export const casinoListJson = [
  {
    title: '20 20 teenpatti',
    eventId: '3030',
    shortName: 'teen20',
    img: "/images/casino/teen20.jpg",
    casinoSort: "marketWhite"
  },

  {
    title: 'lucky 7 - b',
    eventId: '3032',
    shortName: 'lucky7eu',
    img: "/images/casino/lucky7eu.jpg",
    casinoSort: "marketWhite"
  },

  {
    title: '20 20 dragon tiger',
    eventId: '3035',
    shortName: 'dt20',
    img: "/images/casino/dt20.jpg",
    casinoSort: "marketWhite"
  },

  {
    title: 'amar akbar anthony',
    eventId: '3056',
    shortName: 'aaa',
    img: "/images/casino/aaa.jpg",
    casinoSort: "marketWhite"
  },

  {
    title: 'worli matka',
    eventId: '3054',
    shortName: 'worli',
    img: "/images/casino/worli.jpg",
    casinoSort: "marketWhite"
  },

  {
    title: '20-20 Dargon tiger 2',
    eventId: '3059',
    shortName: 'dt202',
    img: "/images/casino/dt202.jpg",
    casinoSort: "marketWhite"
  },

  {
    title: 'teenpatti 1-day',
    eventId: '3031',
    shortName: 'teen',
    img: "/images/casino/teenpatti.jpg",
    casinoSort: "marketWhite"
  },

  {
    title: '32 cards b',
    eventId: '3034',
    shortName: 'card32eu',
    img: "/images/casino/card32b.jpg",
    casinoSort: "marketWhite"
  },

  {
    title: 'teenpatti test',
    eventId: '3048',
    shortName: 'teen9',
    img: "/images/casino/teentest.jpg",
    casinoSort: "marketWhite"
  },
  {
    title: '32 cards a',
    eventId: '3055',
    shortName: 'card32-a',
    img: "/images/casino/card32a.jpg",
    casinoSort: "marketWhite"
  },
  {
    title: 'Andar-Bahar',
    eventId: '3043',
    shortName: 'abj',
    img: '/images/casino/andar-bahar.jpg',
    casinoSort: "marketWhite"

},

  
];