
import Inplay from "../../../../Assest/images/SubHeaderImage/Inplay.webp"
import Cricket from "../../../../Assest/images/SubHeaderImage/Cricket.webp"
import Soccer from "../../../../Assest/images/SubHeaderImage/Soccer.webp"
import Tennis from "../../../../Assest/images/SubHeaderImage/Tennis.webp"
import PremiumNotebook from "../../../../Assest/images/SubHeaderImage/Premium-Notebook.webp";
import LiveCasino from "../../../../Assest/images/SubHeaderImage/Live-Casino.webp";
import Election from "../../../../Assest/images/SubHeaderImage/Election.webp";
import LiveCard from "../../../../Assest/images/SubHeaderImage/Live-Card.webp";
import SlotGame from "../../../../Assest/images/SubHeaderImage/Slot-game.webp";
import More from "../../../../Assest/images/SubHeaderImage/More.png";
import SportsBook from "../../../../Assest/images/SubHeaderImage/Sportsbook.webp";
import aVIATOR from "../../../../Assest/images/aVIATOR.png"


const matchList = JSON.parse(localStorage.getItem("MatchListData"));
const filteredData = matchList?.sportlist?.data ? matchList?.sportlist?.data?.filter((item) => item.sportId == 999) : [];


export const SubHeaderMenu = [
  {
    icon: Inplay,
    text: 'Home',
    link: '/'
  },
  {
    icon: Cricket,
    text: 'Cricket',
    link: '/login',
    count: "4",
  },
  {
    icon: Soccer,
    text: 'Soccer',
    link: '/login',
    count: "1",
  },
  {
    icon: Tennis,
    text: 'Tennis',
    link: '/login',
    count: "2",
  },
  {
    icon: Election,
    text: 'Election',
    link: `/viewMatch/${filteredData[0]?.marketId}/${filteredData[0]?.eventId}`, 
    count: "999",
  },
  {
    icon: LiveCasino,
    text: 'Int Casino',
    link: '/login'
  },
  {
    icon: LiveCard,
    text: 'Dia Casino',
    link: '/login'
  },
  {
    icon: LiveCard,
    text: 'Live Card',
    link: '/login'
  },
  {
    icon: SlotGame,
    text: 'Slot Games',
    link: '/login'
  }

];


export const SubHeaderMobile = [
  {
    icon: Inplay,
    text: 'Home',
    link: '/'
  },
  {
    icon: Cricket,
    text: 'Cricket',
    link: '/login',
    count: "4",
  },
  {
    icon: Soccer,
    text: 'Football',
    link: '/login',
    count: "1",
  },
  {
    icon: Tennis,
    text: 'Tennis',
    link: '/login',
    count: "2",
  },
  {
    icon: LiveCard,
    text: 'Int Casino',
    link: '/login'
  },
  // {
  //   icon: LiveCasino,
  //   text: 'Casino',
  //   link: '/login',
  //   count: "",
  // },

  // {
  //   icon: aVIATOR,
  //   text: 'QTeck',
  //   link: '/login',
  //   count: "",
  // },

]