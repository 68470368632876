import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import moment from "moment";
import { userAction } from "../../../redux/action/userAction";
import { useDispatch, useSelector } from "react-redux";
import NavigatePathComponent from "../../../component/Auth/Header/NavigatePathComponent/NavigatePathComponent";


function AccountStatement() {
    const [active, setActive] = useState(false);
    const [payloadData, setPayloadData] = useState({
        fromDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
        toDate: moment().format('YYYY-MM-DD')
    });
    const dispatch = useDispatch();
    const { accountStatement } = useSelector(state => state.authentication);
    useEffect(() => {
        AOS.init();
        AOS.refresh();
        dispatch(userAction.accountStatement());
    }, [dispatch]);

    const toggleAccordion = (index) => {
        setActive(prevState => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    const groupedData = {};
    accountStatement?.forEach(item => {
        const dateKey = moment(item.date).format("YYYY-MM-DD");
        groupedData[dateKey] = groupedData[dateKey] || [];
        groupedData[dateKey].push(item);
    });


    let totalAmount = 0;
    accountStatement?.map((data, key) => {
        totalAmount += data.amount
    })
    let balance = 0;
    let showAmount = 0;
    let finalData = accountStatement?.map((data, key) => {
        balance = totalAmount - showAmount;
        showAmount += data.amount;
        const pushObj = {
            amount: data.amount,
            date: data.date,
            balance: balance,
            gameType: data.gameType,
            remark: data.remark,
            userRemark: data.userRemark,
            statementFor: data.statementFor,
            isComm: data.isComm,
            marketId: data.marketId,
            createdAt: data.createdAt,
            selectionId: data && data.selectionId ? data.selectionId : "0",
        };
        return pushObj;
    });


    const handleChange = (e) => {
        const { name, value } = e.target;
        setPayloadData({
            ...payloadData,
            [name]: value
        });
    };

    const handleSubmit = () => {
        dispatch(userAction.accountStatement(payloadData));

    }

 



    return (
        <>
            <section className="w-full bg-black">
                <NavigatePathComponent showPathName={"Account Statement"} />
                <div className="relative mx-auto text-center w-full max-w-6xl shadow-xl ring-1 ring-gray-900/5 sm:rounded-md py-4">
                    <div className="px-2 space-y-1 h-auto afterFooter">
                        <div>
                            <div className="flex justify-start relative lg:gap-3 gap-1">
                                <input
                                    type="date"
                                    name="toDate"
                                    id="toDate"
                                    value={payloadData.toDate}
                                    onChange={handleChange}
                                    className="border bg-white rounded-full px-2 py-1"
                                />
                                <input
                                    type="date"
                                    name="fromDate"
                                    id="fromDate"
                                    value={payloadData.fromDate}
                                    onChange={handleChange}
                                    className="border bg-white rounded-full px-2 py-1"
                                />
                                <button type="submit" className="bg-[#f36c21] text-white text-xs px-4 py-0.5 lg:w-72 w-56 rounded-full" onClick={() => handleSubmit()}>Search</button>
                            </div>
                        </div>
                        <div className="py-4 space-y-1">
                          
                            {finalData?.length > 0 && (
                                <div class="flex flex-col">
                                    <div class="overflow-x-auto shadow-md sm:rounded-lg">
                                        <div class="inline-block min-w-full align-middle">
                                            <div class="overflow-hidden ">
                                                <table class="min-w-full divide-y divide-gray-200 table-fixed dark:divide-gray-700">
                                                    <thead class="bg-gray-600 dark:bg-gray-700">
                                                        <tr>
                                                          
                                                            <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-100 uppercase dark:text-gray-400"> Date</th>
                                                            <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-100 uppercase dark:text-gray-400">DESC</th>
                                                            <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-100 uppercase dark:text-gray-400"> Amount</th>
                                                            <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-100 uppercase dark:text-gray-400"> Balance</th>
                                                            <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-100 uppercase dark:text-gray-400"> Type</th>
                                                        </tr>
                                                    </thead>
                                                    {finalData?.map((item, index) => (
                                                        <tbody class="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700 text-start" key={index}>
                                                            <tr class="hover:bg-gray-100 dark:hover:bg-gray-700">
                                                                <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white">{moment(item.date).format('DD/MM/YYYY hh:mm:ss A')}</td>
                                                                <td class="py-4 px-6 text-sm font-medium text-gray-500 lg:whitespace-normal whitespace-nowrap dark:text-white">{item.remark}</td>
                                                                <td class={`py-4 px-6 text-sm  font-bold whitespace-nowrap dark:text-white ${item.amount > 0 ? "text-green-600" : item.amount < 0 ? "text-red-600" : "text-gray-900"}`}>{parseFloat(item.amount).toFixed(2).replace(/\.?0+$/, '')}</td>
                                                                <td class="py-4 px-6 text-sm font-bold text-gray-500 whitespace-nowrap dark:text-white">{parseFloat(item.balance).toFixed(2).replace(/\.?0+$/, '')}</td>
                                                                <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white capitalize">{item.statementFor}</td>
                                                            </tr>
                                                        </tbody>

                                                    ))}
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AccountStatement;











{/*line number 105 {Object.entries(groupedData).map(([date, data], index) => (
                                <div className=" md:flex w-full" key={index}>
                                    <div className="flex-1">
                                        <div className="bg-orange-500 text-white flex items-center justify-between text-start py-2 px-3 rounded-t-2xl" onClick={() => toggleAccordion(index)}>
                                            <span className="text-sm font-normal">
                                                {moment(date).format("MMMM Do YYYY")}
                                            </span>
                                            <span className={`absolute right-6  rounded-full  transition-transform transform duration-200 ${active[index] ? 'rotate-180' : ''}`}>
                                                <MdKeyboardArrowUp size={25} />
                                            </span>
                                        </div>
                                        {active[index] && (
                                            <div className="text-start bg-white w-full max-w-6xl  transition duration-700 ease-in-out border-[#7A3C1D]">
                                                <div className="py-2 overflow-x-auto  text-black">
                                                    <div className="w-full text-sm text-gray-500 dark:text-gray-400 divide-y ">
                                                        {data?.map((item, index) => (
                                                            <div className="flex py-2 px-3">
                                                                <div className="text-gray-700 w-2/3 space-y-1">
                                                                    <div className="text-sm font-normal capitalize text-gray-600">{item.ledgerType}</div>
                                                                    <Link to={"/app/profitloss/details/" + item.marketId}>
                                                                        <div className="text-sm font-normal capitalize text-blue-600 underline cursor-pointer">{item.eventName}</div>
                                                                    </Link>
                                                                    <div>
                                                                        <span className="text-sm font-semibold capitalize text-gray-700 pr-1"> Settled Date: </span>
                                                                        <span>{moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss a")}</span>
                                                                    </div>
                                                                    <div>
                                                                        <span className="text-sm font-semibold capitalize text-gray-700 pr-1">Winner: </span>

                                                                        {item.remark}</div>
                                                                </div>
                                                                <div className="text-black/80 w-1/3 flex justify-between">
                                                                    <div className="space-y-1.5">
                                                                        <div className="text-xs font-semibold text-gray-700 capitalize">Comm: </div>
                                                                        <div className="text-xs font-semibold text-gray-700 capitalize">Net Win: </div>
                                                                    </div>
                                                                    <div>
                                                                        <div className={`text-xs font-semibold  capitalize ${item.amount >= 0 ? "text-green-500" : "text-red-500"}`}>{item.amount}</div>
                                                                        <div className={`text-xs font-semibold capitalize ${totalAmount >= 0 ? "text-green-500" : "text-red-500"}`}>{totalAmount}</div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        ))}
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))} */}
