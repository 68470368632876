import React from "react";
import { connect } from "react-redux";
import FlipCountdown from '@rumess/react-flip-countdown';
import axios from "axios";
import io from 'socket.io-client';
import moment from "moment";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorTost from './casino_components/ErrorTost';
import "./casino.css";
import Teenpattit20rules from './images/tponeday-rules.jpg';
import AnderBahar2 from './images/ab2-rules.webp';
import BetLocked from "./casino_components/BetLocked";
import PlaceBetMobileBmx from "./casino_components/PlaceBetMobileBmx";
import ResultModelBmx from "./casino_components/ResultModelBmx";
import RoundedTabBmx from "./casino_components/RoundedTabBmx";
import PageHeaderTheme from "./casino_components/PageHeaderTheme";
import PlaceBetMobileBmxBet from "./casino_components/PlaceBetMobileBmxBet";
import CasinoTab from "./casino_components/CasinoTab";
import Desktop30 from "./casino_components/Desktop30";
import Mobile30 from "./casino_components/Mobile30";
import { httpPost, httpPostBet } from "../../../../middelware/Http";
import Loader from "../../../../component/Loader/Loader";
import Card from "./casino_components/Card";
import { message } from "antd";
import GameCard from "./casino_components/GameCard";
import GameCard2 from "./casino_components/GameCard2";
import BetLockedRoundedBmx from "./casino_components/BetLockedRoundedBmx";
import { BsFillSuitHeartFill, BsSuitClubFill, BsSuitDiamondFill, BsSuitSpadeFill } from "react-icons/bs";

class Andarbahar2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      backBetModal: false,
      backBetModalMobile: false,
      Result: false,
      result: {},
      betSlipData: {},
      count: 0,
      time: 7,
      casinoData: [],
      showLoader: false,
      fieldsbet: {},
      errorsbet: {},
      ResultModel: false,
      casinoDetails: {},
      fetchData: "",
      socketURL: "",
      tvUrl: "",
      cacheURL: "",
      betStatus: "",
      cashinoStatus: "",
      eventId: "",
      minStake: "",
      maxStake: "",
      name: "",
      shortName: "abj",
      betList: [],
      betresponse: [],
      setting: {},
      posArray: {},
      currentRoundId: 0,
      oddsDifference: 0,
      totalProfitLoss: 0,
      activeTab: 1,
    };
    this.section1Ref = React.createRef();
    this.scrollTimeout = null;
    this.inputRef = React.createRef();
  }


  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.users && nextProps.users.betPlaceSucess) {
      return {
        ...nextProps,
        backBetModal: false,
        backBetModalMobile: false,
        betSlipData: {},
      }
    } else {
      return {
        ...nextProps,

      }
    }
  }
  increaseCount = () => {
    const increasedCount = (parseFloat(this.state.count) + 0.01).toFixed(2);
    this.setState({ count: parseFloat(increasedCount) });
  };

  decreaseCount = () => {
    const decreasedCount = (parseFloat(this.state.count) - 0.01).toFixed(2);
    this.setState({ count: parseFloat(decreasedCount) });
  };

  componentDidMount() {

    let requestData = {
      eventId: 3043,
    }
    this.getDidMountData(requestData)
  }

  getDidMountData = async (requestData) => {
    this.setState({ showLoader: true })
    let getCasinoDetails = await httpPost(`casino/getDiamondCasinoByEventId`, requestData)
    if (!getCasinoDetails.error) {
      let casinoDetails = getCasinoDetails.data
      this.setState({
        casinoDetails: casinoDetails,
        fetchData: casinoDetails && casinoDetails.fetchData ? casinoDetails.fetchData : {},
        socketURL: casinoDetails && casinoDetails.socketURL ? casinoDetails.socketURL : {},
        tvUrl: casinoDetails && casinoDetails.videoUrl1 ? casinoDetails.videoUrl1 : "",
        cacheURL: casinoDetails && casinoDetails.cacheURL ? casinoDetails.cacheURL : {},
        betStatus: casinoDetails && casinoDetails.betStatus ? casinoDetails.betStatus : "",
        cashinoStatus: casinoDetails && casinoDetails.cashinoStatus ? casinoDetails.cashinoStatus : "",
        eventId: casinoDetails && casinoDetails?.eventId ? casinoDetails?.eventId : "",
        minStake: casinoDetails && casinoDetails.minStake ? casinoDetails.minStake : "",
        maxStake: casinoDetails && casinoDetails.maxStake ? casinoDetails.maxStake : "",
        name: casinoDetails && casinoDetails.name ? casinoDetails.name : "",
        shortName: casinoDetails && casinoDetails.shortName ? casinoDetails.shortName : "abj",
        setting: casinoDetails && casinoDetails.setting ? casinoDetails.setting : {},
        oddsDifference: casinoDetails && casinoDetails.setting && casinoDetails.setting.oddsDifference ? casinoDetails.setting.oddsDifference : 0,
      })
      if (casinoDetails?.eventId) {
        this.betList(casinoDetails?.eventId)
      }

      // const socketPerm = casinoDetails.fetchData
      if (casinoDetails?.socketURL) {
        await this.callSocket(casinoDetails?.socketURL, casinoDetails?.shortName)
      }
      // else {
      //   await this.callCache(casinoDetails.cacheURL)
      // }
    } else {

    }
    this.setState({ showLoader: false })
  }

  callSocket = async (socketURL, shortName) => {
    const socket = io.connect(socketURL, {
      transports: ["websocket"],
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: 99,
    });
    socket.emit('JoinRoom', shortName);
    socket.on(shortName, data => {
      this.setState({ currentRoundId: data && data.data && data.data.t1 && data.data.t1[0] && data.data.t1[0].mid ? data.data.t1[0].mid : 0 })
      this.setState({ casinoData: data })
      this.updatePos()
    })
  }

  callCache = async (cacheUrl) => {
    await this.getMarketCacheUrl(cacheUrl);
    this.cacheInterval = setInterval(async () => {
      await this.getMarketCacheUrl(cacheUrl);
    }, 1000);
  }

  getMarketCacheUrl = async (cacheURL) => {
    let config = {
      method: 'GET',
      maxBodyLength: Infinity,
      url: cacheURL,
      headers: { 'Content-Type': 'application/json' },
    };
    axios.request(config).then((response) => {
      this.setState({ casinoData: response.data.data })
    }).catch((error) => {
      console.error('cache data url error ', error);
    });
  }

  updatePos = async () => {
    const { betList, currentRoundId } = this.state;
    if (betList && betList.length > 0) {
      const filteredBets = betList.filter((element) => element.roundId == currentRoundId);
      let pushPos = {};
      filteredBets.forEach((bet) => {
        const posArray = bet.posArray;
        Object.entries(posArray).forEach(([key, value]) => {
          pushPos[key] = (pushPos[key] || 0) + Number(value);
        });
      });

      this.setState({ posArray: pushPos });
    }
  }

  betList = async (eventId) => {
    let betReq = {
      "eventId": eventId,
      fromDate: moment().format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
    };

    let getCasinoDetails = await httpPost(`casino/diamondBetsList`, betReq)
    let totalProfitLoss = 0
    let betList = getCasinoDetails?.data?.casinoBetData
    if (betList && betList.length > 0) {
      betList.map((bet, key) => {
        let profitLoss = "Not Declare"
        let profitLossCount = 0
        if (bet.isDeclare) {
          profitLossCount = bet.profitLoss ? bet.profitLoss : 0
          profitLoss = bet.profitLoss
        }
        totalProfitLoss += Number(profitLossCount)
        betList[key].profitLoss = profitLoss
      })
    }
    if (getCasinoDetails) {
      this.setState({ betList: betList, totalProfitLoss: totalProfitLoss })
    }
  }

  componentWillUnmount() {
    clearInterval(this.cacheInterval);
    clearTimeout(this.timer);
    if (this.inputRef.current) {
      this.inputRef.current.removeEventListener('touchstart', this.handleTouchStart);
    }
  }

  updateStackOnclic = (num) => {
    let { betSlipData } = this.state;
    betSlipData.stake = num;
    this.setState({
      betSlipData: betSlipData,
    });
  };

  updateStackOnclick = (num) => {
    let { betSlipData } = this.state;
    betSlipData.stake = num;
    this.setState({
      betSlipData: betSlipData,
    });
  };

  updateStake = (event) => {
    try {
      let { value } = event.target;
      let { betSlipData } = this.state;
      if (value.startsWith('0') && value.length > 1) {
        value = value.slice(1);
      }
      betSlipData.stake = value;
      this.setState({
        betSlipData: betSlipData,
      });
    } catch (error) {
      console.error('Error updating stake:', error);
    }
  };

  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;

    if (value < 0 || isNaN(Number(value))) {
      value = 0;
    }

    if (value[0] === '0' && value.length > 1) {
      value = value.substring(1);
    }

    let { betSlipData } = this.state;
    betSlipData.stake = value;
    this.setState({
      betSlipData: betSlipData,
      [name]: Number(value),
    });
  };


  placeBet = async () => {
    const { casinoData } = this.state;
    this.setState({ LoadingBet: true })
    let { data } = casinoData ? casinoData : {};
    let t1 = data && data.t1 && data.t1[0] ? data.t1[0] : {};
    let betObject = {
      "roundId": t1.mid,
      "sid": this.state.betSlipData.sid,
      "rate": this.state.count + "",
      "amount": Number(this.state.betSlipData.stake),
      "casinoType": this.state.shortName ? this.state.shortName : "abj",
      "eventId": 3043,
      "betFor": this.state.betFor + "",
      "nat": this.state.betSlipData.nat,
    }


    const result = await httpPostBet("casino/casinoBetPlace", betObject);
    if (result) {
      if (result.error === false) {
        this.setState(
          {betresponse: result.data},
        );
        message.success(result.message)
        // toast.success(<ErrorTost message={result.message} />);
        this.betList(3043)
        // this.props.dispatch(userActions.getUserBalance());
      }
      else {
        message.error(result.message)
        // toast.error(<ErrorTost message={result.message} />);
      }
    }
    this.handleClose();
    this.setState({ LoadingBet: false })
  };

  handleBackOpen = (data) => {
    console.log(data, "ab2/3043ab2/3043");
    this.betForSet(data?.nat)
    if (this.scrollTimeout) {
      clearInterval(this.scrollTimeout);
    }
    this.setState({ backBetModal: true, backBetModalMobile: true, betSlipData: { ...data, stake: "0" }, count: data && data.odds ? data.odds : data.b1, time: 7, });
    this.scrollTimeout = setInterval(() => {
      this.setState(prevState => ({ time: prevState.time - 1 }), () => {
        if (this.state.time < 0) {
          this.setState({ backBetModal: false });
        }
      });
    }, 1000);
  };



  betForSet = (nat) => {
    let value = nat;
    if (value === "SA" || value === "1st Bet" || value === "2nd Bet" || value === "SB") {
      this.setState({ betFor: "andarBahar" })
    } else if (value === "Joker A" || value === "Joker 2" || value === "Joker 3" || value === "Joker 4" || value === "Joker 5" || value === "Joker 6" || value === "Joker 7" || value === "Joker 8" || value === "Joker 9" || value === "Joker 10" || value === "Joker J" || value === "Joker Q" || value === "Joker K") {
      this.setState({ betFor: "cards" })
    } else if (value === "Joker Diamond" || value === "Joker Heart" || value === "Joker Club" || value === "Joker Spade") {
      this.setState({ betFor: "color" })
    } else if (value === "Joker Odd" || "Joker Even") {
      this.setState({ betFor: "oddEven" })
    }
  }

  handleResultModel = (data) => {
    this.setState({ ResultModel: true, result: data });
  };

  handleClose = () => {
    this.setState({ backBetModal: false, backBetModalMobile: false });
  };

  handleCloseResult = () => {
    this.setState({ ResultModel: false });
  };

  handleTabClick = (tabIndex) => {
    this.setState({ activeTab: tabIndex });
  };


  render() {
    let clientNotification = JSON.parse(localStorage.getItem('notification'))
    const { casinoData, name, shortName, ResultModel, time, count, backBetModal, tvUrl, betList, showLoader, LoadingBet, posArray, oddsDifference, totalProfitLoss, activeTab, minStake, maxStake } = this.state;
    let { data, result } = casinoData ? casinoData : {};

    let t1 = data && data.t1 && data.t1[0] ? data.t1[0] : {};
    // let t2 = data && data.t2 ? data.t2 : {};
    let image = t1 && t1.cards ? t1.cards.split(',') : [];
    let SA = data && data.t2 && data.t2[0] ? data.t2[0] : {};
    let SAFBet = data && data.t2 && data.t2[1] ? data.t2[1] : {};
    let SASBet = data && data.t2 && data.t2[2] ? data.t2[2] : {};
    let SB = data && data.t2 && data.t2[3] ? data.t2[3] : {};
    let SBFBet = data && data.t2 && data.t2[4] ? data.t2[4] : {};
    let SBSBet = data && data.t2 && data.t2[5] ? data.t2[5] : {};
    let CardA = data && data.t2 && data.t2[6] ? data.t2[6] : {};
    let Card2 = data && data.t2 && data.t2[7] ? data.t2[7] : {};
    let Card3 = data && data.t2 && data.t2[8] ? data.t2[8] : {};
    let Card4 = data && data.t2 && data.t2[9] ? data.t2[9] : {};
    let Card5 = data && data.t2 && data.t2[10] ? data.t2[10] : {};
    let Card6 = data && data.t2 && data.t2[11] ? data.t2[11] : {};
    let Card7 = data && data.t2 && data.t2[12] ? data.t2[12] : {};
    let Card8 = data && data.t2 && data.t2[13] ? data.t2[13] : {};
    let Card9 = data && data.t2 && data.t2[14] ? data.t2[14] : {};
    let Card10 = data && data.t2 && data.t2[15] ? data.t2[15] : {};
    let CardJ = data && data.t2 && data.t2[16] ? data.t2[16] : {};
    let CardQ = data && data.t2 && data.t2[17] ? data.t2[17] : {};
    let CardK = data && data.t2 && data.t2[18] ? data.t2[18] : {};
    let Spade = data && data.t2 && data.t2[19] ? data.t2[19] : {};
    let Club = data && data.t2 && data.t2[20] ? data.t2[20] : {};
    let Heart = data && data.t2 && data.t2[21] ? data.t2[21] : {};
    let Diamond = data && data.t2 && data.t2[22] ? data.t2[22] : {};
    let Odd = data && data.t2 && data.t2[23] ? data.t2[23] : {};
    let Even = data && data.t2 && data.t2[24] ? data.t2[24] : {};

    return (
      <>

        {LoadingBet === true ?
          <>
            <PlaceBetMobileBmxBet
              betSlipData={this.state.betSlipData}
              time={time}
              count={count}
            />
          </> :
          <>
            {backBetModal && backBetModal === true ? (
              <PlaceBetMobileBmx
                betSlipData={this.state.betSlipData}
                updateStackOnclic={this.updateStackOnclic}
                placeBet={this.placeBet}
                handleClose={this.handleClose}
                section1Ref={this.section1Ref}
                time={time}
                inputChange={this.inputChange}
                decreaseCount={this.decreaseCount}
                increaseCount={this.increaseCount}
                count={count}
              />
            ) : null}
          </>
        }

        {showLoader ?
          <Loader active={showLoader === true} />
          :
          <div className={`w-full relative h-full  overflow-y-auto text-sm flex `}>
            {this.state && ResultModel === true ? (
              <ResultModelBmx
                handleClose={this.handleCloseResult}
                name={name ? name : "Andar Bahar2"}
                shortName={shortName ? shortName : "abj"}
                result={this.state.result}
              />
            ) : null}


            <div className="lg:flex block w-full lg:space-x-2">

              {/* <div className="lg:w-[15%] w-full bg-fuchsia-200 lg:px-2 lg:block hidden"></div> */}

              <div className="lg:hidden block">
                <CasinoTab
                  activeTab={activeTab}
                  handleTabClick={this.handleTabClick}
                  ruleImage={AnderBahar2}
                  t1={t1}
                  totalBet={betList && betList.length ? betList.length : "0"}
                />
                {/* <CasinoTab
                  activeTab={activeTab}
                  handleTabClick={this.handleTabClick}
                  ruleImage={AndarBahar2}
                  t1={t1}
                  totalBet={betList && betList.length ? betList.length : "0"}
                /> */}
              </div>


              <div className="w-full lg:flex">

                {activeTab === 1 ? (
                  <div className="w-full lg:flex block">
                    <div className="lg:w-[70%] w-full ">
                      <PageHeaderTheme
                        PageTitle={name ? name : "Andar Bahar2"}
                        ruleImage={AnderBahar2}
                        t1={t1}
                        minStake={minStake}
                        maxStake={maxStake}
                      />

                      <div className="space-y-2">

                        <div className="bg-black flex justify-between w-full relative md:text-sm text-[10px] xl:h-[360px] md:h-[300px] h-[200px]">
                          <iframe src={tvUrl ? tvUrl : null} title="" className='relative w-full border border-[#cf4b04] ' />
                          <div className=" flex justify-between">
                            <div className="absolute top-0 left-0">
                              <div className="h-full p-2 flex justify-between space-x-2">

                                <div className="flex justify-between items-center space-x-2 ">
                                  <div className="text-white font-medium uppercase text-xs space-y-4">
                                    <p>Andar</p>
                                    <p>Bahar</p>
                                  </div>
                                  <div className="flex justify-center items-center">
                                    <img src={`/cards/${t1 && t1.C1 ? t1.C1 : "1"}.png`} alt="card" className="lg:h-9 h-7 lg:w-7 w-5" />
                                  </div>
                                  <div className="space-y-2 lg:pl-2 pl-1">
                                    <img src={`/cards/${image && image[2] ? image[0] : "1"}.png`} alt="card" className="lg:h-9 h-7 lg:w-7 w-5" />
                                    <img src={`/cards/${image && image[1] ? image[1] : "1"}.png`} alt="card" className="lg:h-9 h-7 lg:w-7 w-5" />
                                  </div>
                                </div>

                                <div className="flex justify-center space-x-1">
                                  <div className="space-y-2">
                                    {image[4] === "1" ? null :
                                      <img src={`/cards/${image && image[4] ? image[4] : "1"}.png`} alt="card" className="lg:h-9 h-7 lg:w-7 w-5" />}
                                    {image[3] === "1" ? null :
                                      <img src={`/cards/${image && image[3] ? image[3] : "1"}.png`} alt="card" className="lg:h-9 h-7 lg:w-7 w-5" />}
                                  </div>
                                  <div className="space-y-2">
                                    {image[6] === "1" ? null :
                                      <img src={`/cards/${image && image[6] ? image[6] : "1"}.png`} alt="card" className="lg:h-9 h-7 lg:w-7 w-5" />}
                                    {image[5] === "1" ? null :
                                      <img src={`/cards/${image && image[5] ? image[5] : "1"}.png`} alt="card" className="lg:h-9 h-7 lg:w-7 w-5" />}
                                  </div>
                                  <div className="space-y-2">
                                    {image[8] === "1" ? null :
                                      <img src={`/cards/${image && image[8] ? image[8] : "1"}.png`} alt="card" className="lg:h-9 h-7 lg:w-7 w-5" />}
                                    {image[7] === "1" ? null :
                                      <img src={`/cards/${image && image[7] ? image[7] : "1"}.png`} alt="card" className="lg:h-9 h-7 lg:w-7 w-5" />}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="flex justify-end items-end absolute bottom-2 right-1 ">
                              <FlipCountdown
                                titlePosition='hidden'
                                hideYear
                                hideMonth
                                hideDay
                                hideHour
                                hideMinute
                                endAtZero
                                size='small'
                                endAt={new Date(Date.now() + 1000 * (t1 && t1.autotime ? t1.autotime : null)).toUTCString()}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="space-y-2">

                          <div className=" odds-bet border border-[#cf4b04]" >
                            <div className="grid lg:grid-cols-2 grid-cols-1 text-center py-2 w-full border border-gray-300">

                              <div className="grid grid-cols-8 gap-4">
                                <div className="py-4">
                                  <p className="text-md font-bold">A</p>
                                </div>

                                <div className="w-full col-span-2">
                                  <div className="relative border-[2px] border-[#FFFF00]">
                                    <div onClick={() => this.handleBackOpen({ data: SA, type: "Yes", odds: 1.95, nat: SA.nat ? SA.nat : SA.nation, sid: SA.sid }, this.section1Ref)}
                                      className="black-text font-bold text-[13px] py-1 cursor-pointer border-[2px] border-[#FFFF00]">
                                      <p>{SA && SA.nat ? SA.nat : "2nd Bet"}</p>
                                      {/* <p>{SA && SA.b1 ? SA.b1 : "2.00"}</p> */}
                                      <p>{1.95}</p>
                                    </div>
                                    {SA && SA.gstatus === "1" ? null : <BetLockedRoundedBmx />}
                                  </div>
                                  <div className={`${posArray[SA.sid] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[12px] font-[600]`} > {posArray[SA.sid] ?? 0.00}</div>
                                </div>

                                <div className="w-full col-span-2">
                                  <div className="relative border-[2px] border-[#FFFF00]">
                                    <div onClick={() => this.handleBackOpen({ data: SAFBet, type: "Yes", odds: 1.95, nat: SAFBet.nat ? SAFBet.nat : SAFBet.nation, sid: SAFBet.sid }, this.section1Ref)}
                                      className="blue-bg white-text font-bold text-[13px] py-1  cursor-pointer border-[2px] border-[#FFFF00]">
                                      <p>{SAFBet && SAFBet.nat ? SAFBet.nat : "2nd Bet"}</p>
                                      <p>{1.95}</p>
                                      {/* <p>{SAFBet && SAFBet.b1 ? SAFBet.b1 : "2.00"}</p> */}
                                    </div>
                                    {SAFBet && SAFBet.gstatus === "1" ? null : <BetLockedRoundedBmx />}
                                  </div>
                                  <div className={`${posArray[SAFBet.sid] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[12px] font-[600]`} > {posArray[SAFBet.sid] ?? 0.00}</div>
                                </div>

                                <div className="w-full col-span-2">
                                  <div className="relative border-[2px] border-[#FFFF00]">
                                    <div onClick={() => this.handleBackOpen({ data: SASBet, type: "Yes", odds: 1.95, nat: SASBet.nat ? SASBet.nat : SASBet.nation, sid: SASBet.sid }, this.section1Ref)}
                                      className="blue-bg white-text font-bold text-[13px] py-1  cursor-pointer border-[2px] border-[#FFFF00]">
                                      <p>{SASBet && SASBet.nat ? SASBet.nat : "2nd Bet"}</p>
                                      {/* <p>{SASBet && SASBet.b1 ? SASBet.b1 : "2.00"}</p> */}
                                      <p>{1.95}</p>
                                    </div>
                                    {SASBet && SASBet.gstatus === "1" ? null : <BetLockedRoundedBmx />}
                                  </div>
                                  <div className={`${posArray[SASBet.sid] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[12px] font-[600]`} > {posArray[SASBet.sid] ?? 0.00}</div>
                                </div>

                                <div className="py-4">
                                  <p className="text-md font-bold">A</p>
                                </div>

                              </div>

                              <div className="grid grid-cols-8 gap-4">
                                <div className="py-4">
                                  <p className="text-md font-bold">B</p>
                                </div>

                                <div className="w-full col-span-2">
                                  <div className="relative border-[2px] border-[#FFFF00]">
                                    <div onClick={() => this.handleBackOpen({ data: SB, type: "Yes", odds: 1.95, nat: SB.nat ? SB.nat : SB.nation, sid: SB.sid }, this.section1Ref)}
                                      className="black-text font-bold text-sm py-1  cursor-pointer border-[2px] border-[#FFFF00] ">
                                      <p>{SB && SB.nat ? SB.nat : "1st Bet"}</p>
                                      {/* <p>{SB && SB.b1 ? SB.b1 : "2.00"}</p> */}
                                      <p>{1.95}</p>
                                    </div>
                                    {SB && SB.gstatus === "1" ? null : <BetLockedRoundedBmx />}
                                  </div>
                                  <div className={`${posArray[SB.sid] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[12px] font-[600]`} > {posArray[SB.sid] ?? 0.00}</div>
                                </div>

                                <div className="w-full col-span-2">
                                  <div className="relative border-[2px] border-[#FFFF00]">
                                    <div onClick={() => this.handleBackOpen({ data: SBFBet, type: "Yes", odds: 1.95, nat: SBFBet.nat ? SBFBet.nat : SBFBet.nation, sid: SBFBet.sid }, this.section1Ref)}
                                      className="blue-bg white-text font-bold text-sm py-1  cursor-pointer  border-[2px] border-[#FFFF00]">
                                      <p>{SBFBet && SBFBet.nat ? SBFBet.nat : "1st Bet"}</p>
                                      <p>{1.95}</p>
                                      {/* <p>{SBFBet && SBFBet.b1 ? SBFBet.b1 : "2.00"}</p> */}
                                    </div>
                                    {SBFBet && SBFBet.gstatus === "1" ? null : <BetLockedRoundedBmx />}
                                  </div>
                                  <div className={`${posArray[SBFBet.sid] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[12px] font-[600]`} > {posArray[SBFBet.sid] ?? 0.00}</div>
                                </div>

                                <div className="w-full col-span-2">
                                  <div className="relative border-[2px] border-[#FFFF00]">
                                    <div onClick={() => this.handleBackOpen({ data: SBSBet, type: "Yes", odds: 1.95, nat: SBSBet.nat ? SBSBet.nat : SBSBet.nation, sid: SBSBet.sid }, this.section1Ref)}
                                      className="blue-bg white-text font-bold text-sm py-1  cursor-pointer border-[2px] border-[#FFFF00] ">
                                      <p>{SBSBet && SBSBet.nat ? SBSBet.nat : "1st Bet"}</p>
                                      {/* <p>{SBSBet && SBSBet.b1 ? SBSBet.b1 : "2.00"}</p> */}
                                      <p>{1.95}</p>
                                    </div>
                                    {SBSBet && SBSBet.gstatus === "1" ? null : <BetLockedRoundedBmx />}
                                  </div>
                                  <div className={`${posArray[SBSBet.sid] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[12px] font-[600]`} > {posArray[SBSBet.sid] ?? 0.00}</div>
                                </div>

                                <div className="py-4">
                                  <p className="text-md font-bold">B</p>
                                </div>

                              </div>

                            </div>

                          </div>

                          <div className="grid lg:grid-cols-2 grid-cols-1 lg:space-x-4 lg:space-y-0 space-y-2 space-x-0 text-center  w-full ">

                            <div className="odds-bet grid grid-cols-2 lg:gap-4 gap-2 w-full py-2 lg:px-4 px-2  border border-[#cf4b04]">

                              <div className="w-full">
                                <p className="text-[16px] darktext font-[600]">{Odd && Odd.nat ? Odd.nat : Odd.nation ? Odd.nation : "ODD"}</p>

                                <div className="relative">
                                  <div onClick={() => this.handleBackOpen({ data: Odd, type: "Yes", odds: Odd.b1, nat: Odd.nat ? Odd.nat : Odd.nation, sid: Odd.sid }, this.section1Ref)}
                                    className="light-blue black-text font-bold text-[13px] py-3  cursor-pointer uppercase ">

                                    <p className="text-[14px] font-[600]">{Odd && Odd.b1 ? Odd.b1 : "2.00"}</p>
                                  </div>
                                  {Odd && Odd.gstatus === "1" ? null : <BetLockedRoundedBmx />}
                                </div>
                                <div className={`${posArray[Odd.sid] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[12px] font-[600]`} > {posArray[Odd.sid] ?? 0.00}</div>
                              </div>

                              <div className="w-full">
                                <p className="text-[16px] darktext font-[600]">{Even && Even.nat ? Even.nat : Even.nation ? Even.nation : "Even"}</p>
                                <div className="relative">
                                  <div onClick={() => this.handleBackOpen({ data: Even, type: "Yes", odds: Even.b1, nat: Even.nat ? Even.nat : Even.nation, sid: Even.sid }, this.section1Ref)}
                                    className="light-blue black-text font-bold text-[13px] py-3  cursor-pointer uppercase ">
                                    <p className="text-[14px] font-[600]">{Even && Even.b1 ? Even.b1 : "2.00"}</p>
                                  </div>
                                  {Even && Even.gstatus === "1" ? null : <BetLockedRoundedBmx />}
                                </div>
                                <div className={`${posArray[Odd.sid] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[12px] font-[600]`} > {posArray[Odd.sid] ?? 0.00}</div>
                              </div>

                            </div>


                            <div className="px-2 odds-bet flex justify-between items-center  lg:gap-4 gap-2 py-2 border border-[#cf4b04]">
                              <div className="w-full space-y-1">
                                <div className="flex justify-center items-center">
                                  <p className="text-black"> <BsSuitSpadeFill size={17} /></p>
                                </div>
                                <div className="relative">
                                  <div 
                         onClick={()=>alert("Bet Not Acceptable")}
                                  // onClick={() => this.handleBackOpen({ data: Spade, type: "Yes", odds: Spade.b1, nat: Spade.nat ? Spade.nat : Spade.nation, sid: Spade.sid }, this.section1Ref)}
                                    className="light-blue font-bold text-sm py-3  cursor-pointer uppercase flex justify-center items-center">
                                    <p className="text-[14px] font-[600]">{Spade && Spade.b1 ? Spade.b1 : "2.00"}</p>
                                  </div>
                                  {Spade && Spade.gstatus === "1" ? null : <BetLockedRoundedBmx />}
                                </div>
                                <div className={`${posArray[Spade.sid] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[12px] font-[600]`} > {posArray[Spade.sid] ?? 0.00}</div>
                              </div>

                              <div className="w-full space-y-1">
                                <div className="flex justify-center items-center">
                                  <p className="text-black"> <BsSuitClubFill size={17} /></p>
                                </div>
                                <div className="relative">
                                  <div 
                                  onClick={()=>alert("Bet Not Acceptable")}
                                  // onClick={() => this.handleBackOpen({ data: Club, type: "Yes", odds: Club.b1, nat: Club.nat ? Club.nat : Club.nation, sid: Club.sid }, this.section1Ref)}
                                    className="light-blue font-bold text-sm py-3  cursor-pointer uppercase flex justify-center items-center ">

                                    <p className="text-[14px] font-[600]">{Club && Club.b1 ? Club.b1 : "2.00"}</p>

                                  </div>
                                  {Club && Club.gstatus === "1" ? null : <BetLockedRoundedBmx />}
                                </div>
                                <div className={`${posArray[Club.sid] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[12px] font-[600]`} > {posArray[Club.sid] ?? 0.00}</div>
                              </div>

                              <div className="w-full space-y-1">
                                <div className="flex justify-center items-center">
                                  <p className="text-red-color"> <BsFillSuitHeartFill size={17} /></p>
                                </div>
                                <div className="relative">
                                  <div 
                                  onClick={()=>alert("Bet Not Acceptable")}
                                  // onClick={() => this.handleBackOpen({ data: Heart, type: "Yes", odds: Heart.b1, nat: Heart.nat ? Heart.nat : Heart.nation, sid: Heart.sid }, this.section1Ref)}
                                    className="light-blue font-bold text-sm py-3  cursor-pointer uppercase flex justify-center items-center ">
                                    <p className="text-[14px] font-[600]">{Heart && Heart.b1 ? Heart.b1 : "2.00"}</p>
                                  </div>
                                  {Heart && Heart.gstatus === "1" ? null : <BetLockedRoundedBmx />}
                                </div>
                                <div className={`${posArray[Heart.sid] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[12px] font-[600]`} > {posArray[Heart.sid] ?? 0.00}</div>
                              </div>

                              <div className="w-full space-y-1">
                                <div className="flex justify-center items-center">
                                  <p className="text-red-color"> <BsSuitDiamondFill size={17} /></p>
                                </div>
                                <div className="relative">
                                  <div 
                                  onClick={()=>alert("Bet Not Acceptable")}
                                  // onClick={() => this.handleBackOpen({ data: Diamond, type: "Yes", odds: Diamond.b1, nat: Diamond.nat ? Diamond.nat : Diamond.nation, sid: Diamond.sid }, this.section1Ref)}
                                    className="light-blue font-bold text-sm py-3  cursor-pointer uppercase flex justify-center items-center ">
                                    <p className="text-[14px] font-[600]">{Diamond && Diamond.b1 ? Diamond.b1 : "2.00"}</p>

                                  </div>
                                  {Diamond && Diamond.gstatus === "1" ? null : <BetLockedRoundedBmx />}
                                </div>
                                <div className={`${posArray[Diamond.sid] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[12px] font-[600]`} > {posArray[Diamond.sid] ?? 0.00}</div>
                              </div>
                            </div>
                          </div>



                          <div className="odds-bet border border-[#cf4b04]">
                            <p className="flex justify-center items-center text-black text-[14px] font-bold py-1">
                              {CardA?.b1 ? CardA.b1 - oddsDifference : 0}
                            </p>
                            <div class="w-11/12 py-1 mx-auto lg:flex justify-center items-center grid grid-cols-7 md:space-x-2 space-x-0 gap-2 px-2 text-center ">
                              <Card
                                handleBackOpen={this.handleBackOpen}
                                Data={CardA}
                                num={"1"}
                                section1Ref={this.section1Ref}
                                posArray={posArray}
                              />
                              <Card
                                handleBackOpen={this.handleBackOpen}
                                Data={Card2}
                                num={"2"}
                                section1Ref={this.section1Ref}
                                posArray={posArray}
                              />
                              <Card
                                handleBackOpen={this.handleBackOpen}
                                Data={Card3}
                                num={"3"}
                                section1Ref={this.section1Ref}
                                posArray={posArray}
                              />
                              <Card
                                handleBackOpen={this.handleBackOpen}
                                Data={Card4}
                                num={"4"}
                                section1Ref={this.section1Ref}
                                posArray={posArray}
                              />
                              <Card
                                handleBackOpen={this.handleBackOpen}
                                Data={Card5}
                                num={"5"}
                                section1Ref={this.section1Ref}
                                posArray={posArray}
                              />
                              <Card
                                handleBackOpen={this.handleBackOpen}
                                Data={Card6}
                                num={"6"}
                                section1Ref={this.section1Ref}
                                posArray={posArray}
                              />
                              <Card
                                handleBackOpen={this.handleBackOpen}
                                Data={Card7}
                                num={"7"}
                                section1Ref={this.section1Ref}
                                posArray={posArray}
                              />
                              <Card
                                handleBackOpen={this.handleBackOpen}
                                Data={Card8}
                                num={"8"}
                                section1Ref={this.section1Ref}
                                posArray={posArray}
                              />
                              <Card
                                handleBackOpen={this.handleBackOpen}
                                Data={Card9}
                                num={"9"}
                                section1Ref={this.section1Ref}
                                posArray={posArray}
                              />
                              <Card
                                handleBackOpen={this.handleBackOpen}
                                Data={Card10}
                                num={"10"}
                                section1Ref={this.section1Ref}
                                posArray={posArray}
                              />
                              <Card
                                handleBackOpen={this.handleBackOpen}
                                Data={CardJ}
                                num={"11"}
                                section1Ref={this.section1Ref}
                                posArray={posArray}
                              />
                              <Card
                                handleBackOpen={this.handleBackOpen}
                                Data={CardQ}
                                num={"12"}
                                section1Ref={this.section1Ref}
                                posArray={posArray}
                              />
                              <Card
                                handleBackOpen={this.handleBackOpen}
                                Data={CardK}
                                num={"13"}
                                section1Ref={this.section1Ref}
                                posArray={posArray}
                              />
                            </div>
                          </div>

                        </div>
                      </div>




                      <div className=" pb-2 bg-black/5 mt-0.5 border border-[#cf4b04]">
                        <RoundedTabBmx />
                        <div className="flex space-x-2 justify-end items-center py-1.5 px-2">
                          {result && result.length > 0 ? result.map((element, index) => (
                            <div onClick={() =>
                              this.handleResultModel(element)} className="result-color w-7 h-7 cursor-pointer flex justify-center items-center rounded-full shadow-md border border-gray-700" >
                              <p className="text-[#ffff33] font-[700] text-[13px]">
                                {element && element.result === "1" ?
                                  <p className="text-[#ff4500] font-[700] text-[13px]">A</p>
                                  : "B"}
                              </p>
                            </div>
                          )) : null}
                        </div>
                      </div>

                    </div>

                    <Desktop30
                      LoadingBet={LoadingBet}
                      betSlipData={this.state.betSlipData}
                      updateStackOnclick={this.updateStackOnclick}
                      inputChange={this.inputChange}
                      decreaseCount={this.decreaseCount}
                      increaseCount={this.increaseCount}
                      placeBet={this.placeBet}
                      handleClose={this.handleClose}
                      count={this.state.count}
                      timerKey={this.state.timerKey}
                      remainingTime={this.state.remainingTime}
                      handleCountdown={this.handleCountdown}
                      updateStake={this.updateStake}
                      betList={betList}
                      time={time}
                      backBetModal={backBetModal}
                    />

                  </div>

                ) : null}


                {activeTab === 2 ? (
                  <Mobile30
                    betList={betList}
                  />
                ) : null}

              </div>

            </div>


          </div>}
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users,
  };
}

export default connect(mapStateToProps)(Andarbahar2);
