import React from "react";
import { connect } from "react-redux";
import FlipCountdown from '@rumess/react-flip-countdown';
import axios from "axios";
import io from 'socket.io-client';
import moment from "moment";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorTost from './casino_components/ErrorTost';
import "./casino.css";
import Teenpattit20rules from './images/tponeday-rules.jpg';
import BetLocked from "./casino_components/BetLocked";
import PlaceBetMobileBmx from "./casino_components/PlaceBetMobileBmx";
import ResultModelBmx from "./casino_components/ResultModelBmx";
import RoundedTabBmx from "./casino_components/RoundedTabBmx";
import PageHeaderTheme from "./casino_components/PageHeaderTheme";
import PlaceBetMobileBmxBet from "./casino_components/PlaceBetMobileBmxBet";
import CasinoTab from "./casino_components/CasinoTab";
import Desktop30 from "./casino_components/Desktop30";
import Mobile30 from "./casino_components/Mobile30";
import { httpPost, httpPostBet } from "../../../../middelware/Http";
import Loader from "../../../../component/Loader/Loader";
import { message } from "antd";
import GameCard from "./casino_components/GameCard";
import GameCard2 from "./casino_components/GameCard2";


class andarbahar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      backBetModal: false,
      offset: 0,
      betSlipData: {},
      fieldsbet: {},
      errorsbet: {},
      count: 0,
      time: 7,
      casinoData: [],
      ResultModel: false,
      backBetModalMobile: false,
      showLoader: false,
      result: {},
      casinoDetails: {},
      fetchData: "",
      socketURL: "",
      tvUrl: "",
      cacheURL: "",
      betStatus: "",
      cashinoStatus: "",
      eventId: "",
      minStake: "",
      maxStake: "",
      name: "",
      shortName: "ab20",
      betList: [],
      betresponse: [],
      newCasinoModal: false,
      BetPlaceDesktop: false,
      betFor: null,
      betOpen: false,
      myBetOpen: false,
      setting: {},
      posArray: {},
      currentRoundId: 0,
      oddsDifference: 0,
      totalProfitLoss: 0,
      activeTab: 1,
      LoadingBet: false,
    };
    this.section1Ref = React.createRef();
    this.scrollTimeout = null;
    this.inputRef = React.createRef();
  }


  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.users && nextProps.users.betPlaceSucess) {
      return {
        ...nextProps,
        backBetModal: false,
        backBetModalMobile: false,
        betSlipData: {},
      }
    } else {
      return {
        ...nextProps,

      }
    }
  }

  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;

    if (value < 0 || isNaN(Number(value))) {
      value = 0;
    }

    if (value[0] === '0' && value.length > 1) {
      value = value.substring(1);
    }

    let { betSlipData } = this.state;
    betSlipData.stake = value;
    this.setState({
      betSlipData: betSlipData,
      [name]: Number(value),
    });
  };


  increaseCount = () => {
    const increasedCount = (parseFloat(this.state.count) + 0.01).toFixed(2);
    this.setState({ count: parseFloat(increasedCount) });
  };

  decreaseCount = () => {
    const decreasedCount = (parseFloat(this.state.count) - 0.01).toFixed(2);
    this.setState({ count: parseFloat(decreasedCount) });
  };

  handleBackOpen = (data) => {
    // alert('1')
    this.betForSet(data.nat)
    if (this.scrollTimeout) {
      clearInterval(this.scrollTimeout);
    }
    this.setState({ backBetModal: true, backBetModalMobile: true, betSlipData: { ...data, stake: "0" }, count: data.rate, time: 7, });
    this.scrollTimeout = setInterval(() => {
      this.setState(prevState => ({ time: prevState.time - 1 }), () => {
        if (this.state.time < 0) {
          clearInterval(this.scrollTimeout);
          this.setState({ backBetModal: false });
        }
      });
    }, 1000);
  };

  betForSet = (nat) => {
    let value = nat.split(' ')[0];
    if (value === "HIGH") {
      this.setState({ betFor: "lowHigh" })
    } else if (value === "LOW") {
      this.setState({ betFor: "lowHigh" })
    } else if (value === "Card") {
      this.setState({ betFor: "cards" })
    } else if (value === "Red") {
      this.setState({ betFor: "color" })
    } else if (value === "Black") {
      this.setState({ betFor: "color" })
    } else if (value === "Odd" || "Even") {
      this.setState({ betFor: "oddEven" })
    }
  }

  handleResultModel = (data) => {
    this.setState({ ResultModel: true, result: data });
  };

  componentDidMount() {

    let requestData = {
      eventId: 3043,
    }
    this.getDidMountData(requestData)
  }

  getDidMountData = async (requestData) => {
    this.setState({ showLoader: true })
    let getCasinoDetails = await httpPost(`casino/getDiamondCasinoByEventId`, requestData)
    if (!getCasinoDetails.error) {
      let casinoDetails = getCasinoDetails.data
      this.setState({
        casinoDetails: casinoDetails,
        fetchData: casinoDetails && casinoDetails.fetchData ? casinoDetails.fetchData : {},
        socketURL: casinoDetails && casinoDetails.socketURL ? casinoDetails.socketURL : {},
        tvUrl: casinoDetails && casinoDetails.videoUrl1 ? casinoDetails.videoUrl1 : "",
        cacheURL: casinoDetails && casinoDetails.cacheURL ? casinoDetails.cacheURL : {},
        betStatus: casinoDetails && casinoDetails.betStatus ? casinoDetails.betStatus : "",
        cashinoStatus: casinoDetails && casinoDetails.cashinoStatus ? casinoDetails.cashinoStatus : "",
        eventId: casinoDetails && casinoDetails?.eventId ? casinoDetails?.eventId : "",
        minStake: casinoDetails && casinoDetails.minStake ? casinoDetails.minStake : "",
        maxStake: casinoDetails && casinoDetails.maxStake ? casinoDetails.maxStake : "",
        name: casinoDetails && casinoDetails.name ? casinoDetails.name : "",
        shortName: casinoDetails && casinoDetails.shortName ? casinoDetails.shortName : "ab20",
        setting: casinoDetails && casinoDetails.setting ? casinoDetails.setting : {},
        oddsDifference: casinoDetails && casinoDetails.setting && casinoDetails.setting.oddsDifference ? casinoDetails.setting.oddsDifference : 0,
      })
      if (casinoDetails?.eventId) {
        this.betList(casinoDetails?.eventId)
      }

      // const socketPerm = casinoDetails.fetchData
      if (casinoDetails?.socketURL) {
        await this.callSocket(casinoDetails?.socketURL, casinoDetails?.shortName)
      }
      // else {
      //   await this.callCache(casinoDetails.cacheURL)
      // }
    } else {

    }
    this.setState({ showLoader: false })
  }

  callSocket = async (socketURL, shortName) => {
    const socket = io.connect(socketURL, {
      transports: ["websocket"],
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: 99,
    });
    socket.emit('JoinRoom', shortName);
    socket.on(shortName, data => {
      this.setState({ currentRoundId: data && data.data && data.data.t1 && data.data.t1[0] && data.data.t1[0].mid ? data.data.t1[0].mid : 0 })
      this.setState({ casinoData: data })
      this.updatePos()
    })
  }

  callCache = async (cacheUrl) => {
    await this.getMarketCacheUrl(cacheUrl);
    this.cacheInterval = setInterval(async () => {
      await this.getMarketCacheUrl(cacheUrl);
    }, 1000);
  }


  getMarketCacheUrl = async (cacheURL) => {
    let config = {
      method: 'GET',
      maxBodyLength: Infinity,
      url: cacheURL,
      headers: { 'Content-Type': 'application/json' },
    };
    axios.request(config).then((response) => {
      this.setState({ casinoData: response.data.data })
    }).catch((error) => {
      console.error('cache data url error ', error);
    });
  }

  updatePos = async () => {
    const { betList, currentRoundId } = this.state;
    if (betList && betList.length > 0) {
      const filteredBets = betList.filter((element) => element.roundId == currentRoundId);
      let pushPos = {};
      filteredBets && filteredBets.length > 0 && filteredBets.forEach((bet) => {
        const posArray = bet.posArray;
        Object.entries(posArray).forEach(([key, value]) => {
          pushPos[key] = (pushPos[key] || 0) + Number(value);
        });
      });

      this.setState({ posArray: pushPos });
    }
  }

  betList = async (eventId) => {
    let betReq = {
      "eventId": eventId,
      fromDate: moment().format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
    };

    let getCasinoDetails = await httpPost(`casino/diamondBetsList`, betReq)
    let betList = getCasinoDetails?.data?.casinoBetData

    let totalProfitLoss = 0
    if (betList && betList.length > 0) {
      betList.map((bet, key) => {
        let profitLoss = "Not Declare"
        let profitLossCount = 0
        if (bet.isDeclare) {
          profitLossCount = bet.profitLoss ? bet.profitLoss : 0
          profitLoss = bet.profitLoss
        }
        totalProfitLoss += Number(profitLossCount)
        betList[key].profitLoss = profitLoss
      })
    }
    if (getCasinoDetails) {
      this.setState({ betList: betList, totalProfitLoss: totalProfitLoss })
    }
  }

  componentWillUnmount() {
    clearInterval(this.cacheInterval);
    clearTimeout(this.timer);
    if (this.inputRef.current) {
      this.inputRef.current.removeEventListener('touchstart', this.handleTouchStart);
    }
  }

  handleClose = () => {
    this.setState({ newCasinoModal: false, betOpen: false, stakeopen: false, backBetModal: false, backBetModalMobile: false, });
  };
  handleCloseResult = () => {
    this.setState({ ResultModel: false, });
  };


  updateStackOnclic = (num) => {
    let { betSlipData } = this.state;
    betSlipData.stake = num;
    this.setState({
      betSlipData: betSlipData,
    });
  };

  updateStackOnclick = (num) => {
    let { betSlipData } = this.state;
    betSlipData.stake = num;
    this.setState({
      betSlipData: betSlipData,
    });
  };

  updateStake = (event) => {
    try {
      let { value } = event.target;
      let { betSlipData } = this.state;
      if (value.startsWith('0') && value.length > 1) {
        value = value.slice(1);
      }
      betSlipData.stake = value;
      this.setState({
        betSlipData: betSlipData,
      });
    } catch (error) {
      console.error('Error updating stake:', error);
    }
  };


  placeBet = async () => {
    const { casinoData } = this.state;
    this.setState({ LoadingBet: true })
    let { data } = casinoData ? casinoData : {};
    let t1 = data && data.t1 && data.t1[0] ? data.t1[0] : {};
    let betObject = {
      "roundId": t1.mid,
      "sid": this.state.betSlipData.sid,
      "rate": (this.state.count - this.state.oddsDifference) + "",
      "amount": Number(this.state.betSlipData.stake),
      "casinoType": this.state.shortName ? this.state.shortName : "ab20",
      "eventId": 3043,
      "betFor": this.state.betFor + "",
    }

    const result = await httpPost("casino/casinoBetPlace", betObject);
    if (result) {
      if (result.error === false) {

        this.setState(
          {
            betresponse: result.data
          },
        );
        message.success(result.message)
        // toast.success(<ErrorTost message={result.message} />, {
        //   autoClose: 1500,
        // });
        this.betList(3043)
        // this.props.dispatch(userActions.getUserBalance());
      }
      else {
        message.error(result.message)
        // toast.error(<ErrorTost message={result.message} />, {
        //   autoClose: 1500,
        // });
      }
    }
    this.handleClose();
    this.setState({ LoadingBet: false })
  };

  handleTabClick = (tabIndex) => {
    this.setState({ activeTab: tabIndex });
  };


  render() {
    let clientNotification = JSON.parse(localStorage.getItem('notification'))
    const { casinoData, name, shortName, ResultModel, time, count, backBetModal, tvUrl, betList, showLoader, LoadingBet, posArray, oddsDifference, totalProfitLoss, activeTab } = this.state;

    let Andar = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[0] ? casinoData.data.t2[0] : {};
    let Bahar = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[1] ? casinoData.data.t2[1] : {};

    let AndarA = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[0] ? casinoData.data.t2[0] : {};
    let Andar2 = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[1] ? casinoData.data.t2[1] : {};
    let Andar3 = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[2] ? casinoData.data.t2[2] : {};
    let Andar4 = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[3] ? casinoData.data.t2[3] : {};
    let Andar5 = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[4] ? casinoData.data.t2[4] : {};
    let Andar6 = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[5] ? casinoData.data.t2[5] : {};
    let Andar7 = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[6] ? casinoData.data.t2[6] : {};
    let Andar8 = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[7] ? casinoData.data.t2[7] : {};
    let Andar9 = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[8] ? casinoData.data.t2[8] : {};
    let Andar10 = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[9] ? casinoData.data.t2[9] : {};
    let AndarJ = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[10] ? casinoData.data.t2[10] : {};
    let AndarQ = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[11] ? casinoData.data.t2[11] : {};
    let AndarK = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[12] ? casinoData.data.t2[12] : {};

    let BaharA = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[13] ? casinoData.data.t2[13] : {};
    let Bahar2 = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[14] ? casinoData.data.t2[14] : {};
    let Bahar3 = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[15] ? casinoData.data.t2[15] : {};
    let Bahar4 = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[16] ? casinoData.data.t2[16] : {};
    let Bahar5 = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[17] ? casinoData.data.t2[17] : {};
    let Bahar6 = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[18] ? casinoData.data.t2[18] : {};
    let Bahar7 = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[19] ? casinoData.data.t2[19] : {};
    let Bahar8 = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[20] ? casinoData.data.t2[20] : {};
    let Bahar9 = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[21] ? casinoData.data.t2[21] : {};
    let Bahar10 = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[22] ? casinoData.data.t2[22] : {};
    let BaharJ = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[23] ? casinoData.data.t2[23] : {};
    let BaharQ = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[24] ? casinoData.data.t2[24] : {};
    let BaharK = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[25] ? casinoData.data.t2[25] : {};

    let t1 = casinoData && casinoData.data && casinoData.data && casinoData.data.t1 && casinoData.data.t1[0] ? casinoData.data.t1[0] : {};

    return (
      <>

        {LoadingBet === true ?
          <>
            <PlaceBetMobileBmxBet
              betSlipData={this.state.betSlipData}
              time={time}
              count={count}
            />
          </> :
          <>
            {backBetModal && backBetModal === true ? (
              <PlaceBetMobileBmx
                betSlipData={this.state.betSlipData}
                updateStackOnclic={this.updateStackOnclic}
                placeBet={this.placeBet}
                handleClose={this.handleClose}
                section1Ref={this.section1Ref}
                time={time}
                inputChange={this.inputChange}
                decreaseCount={this.decreaseCount}
                increaseCount={this.increaseCount}
                count={count}
              />
            ) : null}
          </>
        }

        {showLoader ?
          <Loader active={showLoader === true} />
          :
          <div className={`w-full relative h-full  page_bg overflow-y-auto text-sm flex`}>
            {ResultModel &&
              ResultModel === true ? (
              <ResultModelBmx
                handleClose={this.handleCloseResult}
                name={name ? name : "Andar Bahar"}
                shortName={shortName ? shortName : "ab20"}
                result={this.state.result}
              />
            ) : null}

            <div className="lg:flex block w-full scroll-md lg:space-x-1.5">

              {/* <div className="lg:w-[15%] w-full bg-fuchsia-200 lg:px-2 lg:block hidden"></div> */}

              <div className="lg:hidden block">
                {/* <CasinoTab
                  activeTab={activeTab}
                  handleTabClick={this.handleTabClick}
                  ruleImage={AndarBahar}
                  t1={t1}
                  totalBet={betList && betList.length ? betList.length : "0"}
                /> */}
              </div>

              <div className="w-full lg:flex">

                {activeTab === 1 ? (

                  <div className="w-full lg:flex block">

                    <div className="lg:w-[70%] w-full bg-white">
                      {/* <PageHeaderTheme
                        PageTitle={name ? name : "Andar Bahar"}
                        ruleImage={AndarBahar}
                        t1={t1}
                      /> */}

                      <div className="bg-black flex justify-between w-full relative md:text-sm text-[10px] xl:h-[460px] md:h-[300px] h-[200px]">
                        <iframe src={tvUrl ? tvUrl : null} title=" " className='relative w-full  ' />
                        <div className=" flex justify-between">
                          <div className="absolute top-0 left-0">
                            <div className="p-1.5">
                              <div className="text-white text-[14px] font-[500] ">CARD</div>
                              <img src={`/cards/${t1 && t1.C1 ? t1.C1 : 1}.png`} alt="card" className="h-10 w-8 border-[1px] border-yellow-300" />
                            </div>
                          </div>

                          <div className="flex justify-end items-end absolute bottom-2 right-1">
                            <FlipCountdown
                              titlePosition='hidden'
                              hideYear
                              hideMonth
                              hideDay
                              hideHour
                              hideMinute
                              endAtZero
                              size='small'
                              endAt={new Date(Date.now() + 1000 * (t1 && t1.autotime ? t1.autotime : null)).toUTCString()}
                            />
                          </div>
                        </div>
                      </div>


                      <div className="mt-2 px-1">

                        <div className="lg:space-y-2 space-y-0">
                          <div className=" Andar-bg lg:flex lg:divide-x divide-black border-[1px] border-black">

                            <div className="lg:w-[10%] w-full text-[14px] lg:border-none border-b-[1px] border-black font-medium black-text flex justify-center items-center">
                              {Andar.nat ? Andar.nat : Andar.nation ? Andar.nation : "ANDAR"}
                            </div>

                            <div class="lg:w-[90%] w-11/12 py-2 mx-auto lg:flex justify-center items-center grid grid-cols-5 md:space-x-2 space-x-0 gap-2 px-2 text-center ">
                              <GameCard
                                handleBackOpen={this.handleBackOpen}
                                Data={AndarA}
                                num={"1"}
                                section1Ref={this.section1Ref}
                                posArray={posArray}
                              />
                              <GameCard
                                handleBackOpen={this.handleBackOpen}
                                Data={Andar2}
                                num={"2"}
                                section1Ref={this.section1Ref}
                                posArray={posArray}
                              />
                              <GameCard
                                handleBackOpen={this.handleBackOpen}
                                Data={Andar3}
                                num={"3"}
                                section1Ref={this.section1Ref}
                                posArray={posArray}
                              />
                              <GameCard
                                handleBackOpen={this.handleBackOpen}
                                Data={Andar4}
                                num={"4"}
                                section1Ref={this.section1Ref}
                                posArray={posArray}
                              />
                              <GameCard
                                handleBackOpen={this.handleBackOpen}
                                Data={Andar5}
                                num={"5"}
                                section1Ref={this.section1Ref}
                                posArray={posArray}
                              />
                              <GameCard
                                handleBackOpen={this.handleBackOpen}
                                Data={Andar6}
                                num={"6"}
                                section1Ref={this.section1Ref}
                                posArray={posArray}
                              />
                              <GameCard
                                handleBackOpen={this.handleBackOpen}
                                Data={Andar7}
                                num={"7"}
                                section1Ref={this.section1Ref}
                                posArray={posArray}
                              />
                              <GameCard
                                handleBackOpen={this.handleBackOpen}
                                Data={Andar8}
                                num={"8"}
                                section1Ref={this.section1Ref}
                                posArray={posArray}
                              />
                              <GameCard
                                handleBackOpen={this.handleBackOpen}
                                Data={Andar9}
                                num={"9"}
                                section1Ref={this.section1Ref}
                                posArray={posArray}
                              />
                              <GameCard
                                handleBackOpen={this.handleBackOpen}
                                Data={Andar10}
                                num={"10"}
                                section1Ref={this.section1Ref}
                                posArray={posArray}
                              />
                              <GameCard
                                handleBackOpen={this.handleBackOpen}
                                Data={AndarJ}
                                num={"11"}
                                section1Ref={this.section1Ref}
                                posArray={posArray}
                              />
                              <GameCard
                                handleBackOpen={this.handleBackOpen}
                                Data={AndarQ}
                                num={"12"}
                                section1Ref={this.section1Ref}
                                posArray={posArray}
                              />
                              <GameCard
                                handleBackOpen={this.handleBackOpen}
                                Data={AndarK}
                                num={"13"}
                                section1Ref={this.section1Ref}
                                posArray={posArray}
                              />
                            </div>

                          </div>

                          <div className="Bahar-bg lg:flex lg:divide-x divide-black border-[1px] border-black">

                            <div className="lg:w-[10%] w-full text-[14px] lg:border-none border-b-[1px] border-black font-medium black-text flex justify-center items-center">
                              {Bahar.nat ? Bahar.nat : Bahar.nation ? Bahar.nation : "BAHAR"}
                            </div>

                            <div class="lg:w-[90%] w-11/12 py-2 mx-auto lg:flex justify-center items-center grid grid-cols-5 md:space-x-2 space-x-0 gap-2 px-2 text-center ">
                              <GameCard2
                                handleBackOpen={this.handleBackOpen}
                                Data={BaharA}
                                num={"1"}
                                section1Ref={this.section1Ref}
                                posArray={posArray}
                              />
                              <GameCard2
                                handleBackOpen={this.handleBackOpen}
                                Data={Bahar2}
                                num={"2"}
                                section1Ref={this.section1Ref}
                                posArray={posArray}
                              />
                              <GameCard2
                                handleBackOpen={this.handleBackOpen}
                                Data={Bahar3}
                                num={"3"}
                                section1Ref={this.section1Ref}
                                posArray={posArray}
                              />
                              <GameCard2
                                handleBackOpen={this.handleBackOpen}
                                Data={Bahar4}
                                num={"4"}
                                section1Ref={this.section1Ref}
                                posArray={posArray}
                              />
                              <GameCard2
                                handleBackOpen={this.handleBackOpen}
                                Data={Bahar5}
                                num={"5"}
                                section1Ref={this.section1Ref}
                                posArray={posArray}
                              />
                              <GameCard2
                                handleBackOpen={this.handleBackOpen}
                                Data={Bahar6}
                                num={"6"}
                                section1Ref={this.section1Ref}
                                posArray={posArray}
                              />
                              <GameCard2
                                handleBackOpen={this.handleBackOpen}
                                Data={Bahar7}
                                num={"7"}
                                section1Ref={this.section1Ref}
                                posArray={posArray}
                              />
                              <GameCard2
                                handleBackOpen={this.handleBackOpen}
                                Data={Bahar8}
                                num={"8"}
                                section1Ref={this.section1Ref}
                                posArray={posArray}
                              />
                              <GameCard2
                                handleBackOpen={this.handleBackOpen}
                                Data={Bahar9}
                                num={"9"}
                                section1Ref={this.section1Ref}
                                posArray={posArray}
                              />
                              <GameCard2
                                handleBackOpen={this.handleBackOpen}
                                Data={Bahar10}
                                num={"10"}
                                section1Ref={this.section1Ref}
                                posArray={posArray}
                              />
                              <GameCard2
                                handleBackOpen={this.handleBackOpen}
                                Data={BaharJ}
                                num={"11"}
                                section1Ref={this.section1Ref}
                                posArray={posArray}
                              />
                              <GameCard2
                                handleBackOpen={this.handleBackOpen}
                                Data={BaharQ}
                                num={"12"}
                                section1Ref={this.section1Ref}
                                posArray={posArray}
                              />
                              <GameCard2
                                handleBackOpen={this.handleBackOpen}
                                Data={BaharK}
                                num={"13"}
                                section1Ref={this.section1Ref}
                                posArray={posArray}
                              />
                            </div>

                          </div>
                        </div>

                        <div className="text-[12px] text-right font-[600] text-[#17a2b8]">
                          Payout : Bahar 1st Card 25% and All Other Andar-Bahar Cards 100%.
                        </div>

                      </div>

                      <div className=" pb-20 bg-black/5 mt-0.5">
                        <RoundedTabBmx />
                        <div className="flex space-x-2 justify-end items-center py-1.5 px-2">
                          {casinoData && casinoData.result && casinoData.result ? casinoData.result.map((element, index) => (
                            <div onClick={() =>
                              this.handleResultModel(element)} className="result-color w-7 h-7 cursor-pointer flex justify-center items-center rounded-full shadow-md border border-gray-700" >
                              <p className={`font-[600] text-[13px] ${element && element.result && element.result === "0" ? "text-[#FFFF2E]" : element && element.result && element.result === "1" ? "text-[#ff4500]" : element && element.result && element.result === "2" ? "text-[#FFFF2E]" : "text-[#FFFF2E]"}`}>
                                {element && element.result && element.result === "0" ? "R" : element && element.result && element.result === "1" ? "L" : element && element.result && element.result === "2" ? "H" : "-"}
                              </p>
                            </div>
                          )) : null}
                        </div>
                      </div>


                    </div>

                    <Desktop30
                      LoadingBet={LoadingBet}
                      betSlipData={this.state.betSlipData}
                      updateStackOnclick={this.updateStackOnclick}
                      inputChange={this.inputChange}
                      decreaseCount={this.decreaseCount}
                      increaseCount={this.increaseCount}
                      placeBet={this.placeBet}
                      handleClose={this.handleClose}
                      count={this.state.count}
                      timerKey={this.state.timerKey}
                      remainingTime={this.state.remainingTime}
                      handleCountdown={this.handleCountdown}
                      updateStake={this.updateStake}
                      betList={betList}
                      time={time}
                      backBetModal={backBetModal}
                    />

                  </div>

                ) : null}

                {activeTab === 2 ? (
                  <Mobile30
                    betList={betList}
                  />
                ) : null}

              </div>

            </div>
          </div>

        }
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users,
  };
}

export default connect(mapStateToProps)(andarbahar);
